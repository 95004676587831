/* You can add global styles to this file, and also import other style files */
@import './scss/material-theme';
@import './scss/quill-theme';
@import './scss/fonts';

:root {
  --primary: #6482d3;
  --font-on-primary: #fff;
  --secondary: #fff;
  --background: #edf0f2;
  --font-main: #1e445e;
  --label-color: #868686;
  --label-font-size: 14px;
  --disabled: #cecbcb;
  --editor-toolbar-bg: #9ba6ad;
  --editor-type-bg: #f4f6f7;
  --green: #69bb84;
  --red: #ff674d;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;

  background: var(--background);

  color: var(--font-main);
  font-family: 'Ubuntu', 'Helvetica Neue', sans-serif;
}

h1 {
  color: var(--font-main);
}

.mat-table {
  overflow: hidden;

  border-radius: 12px;
}

.mat-header-cell,
.mat-sort-header-content {
  color: var(--font-main);
  font-size: 16px;
  font-weight: bold;
}

tr.table-row.mat-row,
tr.table-row.mat-footer-row {
  height: 65px;

  &:hover {
    background: rgba(0, 0, 0, 0.04);

    cursor: pointer;
  }

  &:last-of-type .mat-cell {
    border-bottom-width: 0;
  }
}

.mat-cell {
  color: var(--font-main);
  font-size: 16px;
}

.mat-toolbar-row {
  gap: 20px;
  margin: 40px 0 35px;

  background: #fff;
  border-radius: 12px;

  .mat-slide-toggle {
    font-size: 1rem;
  }
}

.spacer {
  flex-grow: 1;
}

.mat-toolbar {
  background: var(--background);
}

.detail-form-box {
  margin-bottom: 25px;
  padding: 20px 75px 50px 25px;

  background: #fff;
  border-radius: 12px;
}

.detail-form-section {
  margin-bottom: 50px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .detail-form-section-heading {
    margin-top: 0;
    margin-bottom: 20px;

    color: var(--font-main);
    font-size: 18px;
  }
}

.input-columns-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 175px;

  .input-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.image-form-field {
  label {
    display: inline-block;
    margin-bottom: 12px;

    color: var(--label-color);
    font-size: var(--label-font-size);
  }
}

.align-button-icons .mat-button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-btn {
  background-color: rgba(0, 0, 0, 0.04);
}

.lcms-property-icon {
  margin-left: 5px;
  padding: 4px;

  background-color: #eee;
  border-radius: 10px;
}
