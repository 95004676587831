@import '~quill/dist/quill.snow.css';

.ng-dirty {
  quill-editor.ng-invalid {
    .ql-container {
      border: 1px solid #c93d3d;
    }
  }
}

.quill-block {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .ql-toolbar {
    background-color: var(--editor-toolbar-bg);
    border: 0;
  }

  .ql-container {
    height: 200px;

    background-color: var(--editor-type-bg);
    border: 0;
  }

  .ql-stroke {
    stroke: #fff;
  }

  .ql-fill {
    fill: #fff;
  }

  .ql-picker-item {
    .ql-stroke {
      stroke: #000
    }
  }


  label {
    margin-bottom: 10px;

    color: var(--label-color);
    font-size: var(--label-font-size);
  }

  .ql-blank::before {
    content: 'Tragen Sie hier Ihren Text ein...';

    color: #000;
  }
}
