/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  src: local(''),
    url('/assets/fonts/ubuntu-v19-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/ubuntu-v19-latin-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  src: local(''),
    url('/assets/fonts/ubuntu-v19-latin-700.woff2') format('woff2'),
    url('/assets/fonts/ubuntu-v19-latin-700.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}
